<template>
  <v-card>
    <v-card-title class="subtitle-1 lightgray py-2">
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="search"
        :label="$tc('global.buscar')"
        clearable
        solo
        flat
        outlined
        dense
        hide-details
      ></v-text-field>
      <!-- <v-spacer></v-spacer>
      <v-btn color="buttons" depressed dark>
        Empresa
        <v-icon right>add</v-icon>
      </v-btn> -->
    </v-card-title>
    <v-divider></v-divider>
    <template v-if="tocs">
      <v-card-text class="py-4">
        <v-data-table
          :headers="headers"
          :items="tocs"
          :search="search"
          :items-per-page="-1"
          :sort-by="['id']"
          class="data-tables"
          :class="{ 'data-tables__row-click': vueTocsFilho }"
          @click:row="goToTocs"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
        </v-data-table>
      </v-card-text>
    </template>
    <Carregando v-else />
    <v-btn
      :to="{ name: 'AddEmpresa' }"
      v-if="!tocs.length"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import { all } from "@/services/api/tocs.api.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaTocs",
  data() {
    return {
      tocs: [],
      search: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueTocsFilho() {
      return this.getAccess("vueTocsFilho", "visualizar");
    },
    headers() {
      return [
        {
          text: this.$tc("pages.empresa"),
          value: "empresa_nome",
        },
        {
          text: this.$tc("global.documento"),
          value: "empresa_documento",
        },
        {
          text: this.$tc("pages.cidade"),
          value: "empresa_cidade",
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
      ];
    },
  },
  methods: {
    goToTocs(item) {
      if (this.vueTocsFilho) {
        this.$store.commit("UPDATE_DYNAMICCRUMB", item.empresa_nome);
        this.$router.push({
          name: "Empresa",
          params: { empresaId: item.id },
        });
      }
    },
    getTocs() {
      this.$Progress.start();
      all()
        .then((response) => {
          this.tocs = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
  },
  mounted() {
    this.getTocs();
  },
};
</script>

<style></style>
